@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.App {
  vertical-align: top;
  border-color:#8dc73f;
  border-style: solid;
  border-width: 3px;
  width:600px;
  height:300px;
  background-color: #333333;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 1vmin);
  color: white;
  margin-left: auto;
  margin-right: auto;
  margin-bottom:400px;
  color: #D5D5D5;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width:600px;
  background-color: #333333;
  min-height: 45vh;
  color: white;
  margin-left: auto;
  margin-right: auto;
  font-size: calc(10px + 1vmin);
  color: #D5D5D5
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 16px 24px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


button {
  background-color: #8dc73f;
  font-size: large;
  color: white;
}

.App-link {
  color: #61dafb;
}

.widget-title {
  font-size: 22px;
  line-height: 32px;
  border-bottom: 2px solid #8dc73f;
  color: #D5D5D5;
  padding-top: 30px;
    margin-bottom: 10px;
}

input {
  color:#666666;
}

input:disabled {
  background-color: white;
}

.center-bottom {
  text-align: center;
  vertical-align: bottom;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
